import { Link } from "react-router-dom";
import { Link as MUILink } from "@mui/material";

export const CustomLink = ({
  href = "",
  variant = "details",
  underline = "none",
  color = "dark",
  children,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing
}) => {
  return (
    <Link to={href} style={{textDecoration: 'underline'}}>
      <MUILink
        variant={variant}
        underline={underline}
        color={color}
        fontSize={fontSize}
        fontFamily={'Montserrat'}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
      >
        {children}
      </MUILink>
    </Link>
  );
};